import { ReactNode } from 'react';

import Button from '../../Button';

import '../style.scss';

type TooltipWrapperProps = {
  overlay: React.ReactNode;
  isClamped: boolean;
  children: ReactNode;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick?: (e?: React.SyntheticEvent) => void;
};

const TooltipWrapper = ({
  children,
  overlay,
  isClamped,
  onMouseEnter,
  onMouseLeave,
  onClick = undefined,
}: TooltipWrapperProps) => (
  <>
    <span
      className="tooltip-wrapper"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {overlay}
      {isClamped && (
        <Button
          className='tooltip-button show'
          onClick={onClick}
        />
      )}
    </span>

    {children}
  </>
);

export default TooltipWrapper;
