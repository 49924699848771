import { useState, useMemo, useEffect } from 'react';

import Tab from '../../components/Tab';
import ExportTab from './ExportTab';

import { ETabMode, TTabType } from '../../components/Tab/types';
import { useActions } from '../../hooks/useActions';
import ImportTab from './ImportTab';

const ExportImportSettings: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { resetSettingsApi } = useActions();

  useEffect(() => {
    return () => {
      resetSettingsApi();
    };
  }, []);

  const handleSelectedTab = (index: number) => {
    setSelectedTab(index);
  };

  const tabsDetails: TTabType[] = useMemo(
    () => [
      {
        label: 'Export settings',
        index: 0,
        Component: ExportTab,
      },
      {
        label: 'Import settings',
        index: 1,
        Component: ImportTab,
      },
    ],
    []
  );

  return (
    <div className="wrapper wrapper-page import-export-settings-page">
      <h1>Import & Export</h1>

      <Tab
        tabs={tabsDetails}
        selectedTab={selectedTab}
        onChange={handleSelectedTab}
        tabMode={ETabMode.MODE_SELECTED_TAB}
      />
    </div>
  );
};

export default ExportImportSettings;
