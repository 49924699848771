import { useEffect } from 'react';

const useWindowResize = (onResize: () => void) => {
  useEffect(() => {
    onResize();
  }, [onResize]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
};

export default useWindowResize;
