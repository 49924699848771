/* eslint-disable @typescript-eslint/no-explicit-any */
import { TOOLTIP_MAX_WIDTH, TOOLTIP_MAX_HEIGHT } from '../components/TooltipOverlay/constants';
import { Coordinates, POSITION } from '../types/tooltipOverlayTypes';

export const checkIfElementClumped = (element: HTMLElement): boolean => {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const getRenderWidth = ({
  position,
  left,
  right,
  width,
}: {
  position: typeof POSITION[number],
  left: number,
  right: number,
  width: number,
}) => {
  switch (position) {
    case 'left':
      return left;
    case 'right':
      return right;
    case 'top':
    case 'bottom':
      return Math.min(right, left) * 1.8 + width;
    default:
      return width;
  }
};

export const getRenderCoordinates = (
  element: HTMLElement,
): Coordinates => {
  const { top, bottom, left, right, width } = element.getBoundingClientRect();
  const rightArea = document.documentElement.clientWidth - right;
  const bottomArea = document.documentElement.clientHeight - bottom;
  const freeSpaceAroundElement = {
    top: top * (Math.min(rightArea, left) * 1.8 + width),
    left: TOOLTIP_MAX_HEIGHT * left,
    bottom: bottomArea * (Math.min(rightArea, left) * 2 + width),
    right: rightArea * TOOLTIP_MAX_HEIGHT,
  };
  const maxFreeSpace = Math.max(
    freeSpaceAroundElement.top,
    freeSpaceAroundElement.bottom,
    freeSpaceAroundElement.right,
    freeSpaceAroundElement.left,
  );
  const preferredRenderArea = Object.keys(freeSpaceAroundElement).filter(
    (key) => freeSpaceAroundElement[key] === maxFreeSpace
  )[0];
  const renderWidth = getRenderWidth({ left, right: rightArea, width, position: preferredRenderArea });

  return ({
    top: top + window.pageYOffset,
    bottom: bottom + window.pageYOffset,
    left: left + window.pageXOffset,
    right: right + window.pageXOffset,
    position: preferredRenderArea as typeof POSITION[number],
    width: renderWidth,
  });
};

export const getCSSByCoordinates = (
  elementHeight: number,
  elementWidth: number,
  { position, top, left, bottom, right, width }: Coordinates
) => {
  switch (position) {
    case 'right':
      return ({
        top: top - elementHeight / 2,
        left: right,
        right: window.innerWidth - right - (elementWidth ?? TOOLTIP_MAX_WIDTH) > 0 ? 'auto' : -window.pageXOffset,
      });
    case 'left':
      return ({
        top: top - elementHeight / 2,
        right: window.innerWidth - left,
        left: (window.innerWidth - left) > 0 ?
          (left - window.pageXOffset - (elementWidth) > 0 ? 'auto' : window.pageXOffset) :
          (window.innerWidth - left - (elementWidth) > 0 ? 'auto' : window.pageXOffset),
      });
    case 'bottom':
      return ({
        top: bottom,
        left: left - (elementWidth - width) / 2,
        right: window.innerWidth - right - (elementWidth ?? TOOLTIP_MAX_WIDTH) > 0 ? 'auto' : -window.pageXOffset,
      });
    default:
      return ({
        top: top - elementHeight,
        left: left - (elementWidth - width) / 2,
        right: window.innerWidth - right - (elementWidth ?? TOOLTIP_MAX_WIDTH) > 0 ? 'auto' : -window.pageXOffset,
      });
  }
};

export const getStickyAreaWidth = (columns: any[]) => {
  const stickyColumns = columns.filter((th: any) => !!th.dataset.stickyTd);
  const stickyColumnWidth = stickyColumns.reduce((acc: any, current: any) => acc + current.offsetWidth, 0);
  return stickyColumnWidth as number;
};

export const scrollOverlayIntoView = (
  element: HTMLElement | null,
  wrapper: HTMLElement | null,
  wrapperFixedWidth: number
) => {
  if (!element || !wrapper) {
    return;
  }
  const hiddenElementLeftPart = wrapperFixedWidth + wrapper.getBoundingClientRect().left -
    element.getBoundingClientRect().left;
  const shouldScrollWrapper = hiddenElementLeftPart > 0;
  if (shouldScrollWrapper) {
    wrapper.scrollTo(
      wrapper.scrollLeft - hiddenElementLeftPart,
      wrapper.scrollTop
    );
  }
};
