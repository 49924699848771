import { ClearIndicatorProps } from 'react-select';

interface IOption {
  readonly id?: string | number;
  readonly name?: string | number;
  readonly value: string | number;
  readonly label: string | number;
  readonly isDisabled?: boolean;
  readonly options?: IOption[];
}

const ClearIndicator = (props: ClearIndicatorProps<IOption, true>) => {
  const flatOptions = props.options?.map((o) => o.options).flat() ?? [];
  const items = props.getValue().filter((i) => !!flatOptions[flatOptions.findIndex((s) => s?.id === i.id)]?.isDisabled);
  const { clearValue, setValue } = props;
  return (
    <div
      aria-hidden='true'
      className='react-select_single__clear-indicator react-select_multiple__clear-indicator'
      onMouseDown={(event) => {
        event.stopPropagation();
        event.preventDefault();
        items.length ? setValue(items, 'select-option') : clearValue();
      }}
      onClick={() => null}
      onTouchEnd={(event) => {
        event.stopPropagation();
        event.preventDefault();
        items.length ? setValue(items, 'select-option') : clearValue();
      }}
    />
  );
};

export default ClearIndicator;
