/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form';
import Button from '../../../components/Button';
import { columns, nest, stringifyFile } from './utils';
import { useApplySettingsImportMutation, useGetSettingsDiffMutation } from '../../../redux/api/adminApi/settingsApi';
import { ResponseNotificationByRequestList } from '../../../components/ResponseNotificationByRequest';
import { useMemo, useRef, useState } from 'react';
import BasicTable from '../../../components/BasicTable';


const ImportTab: React.FC = () => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    shouldUnregister: true,
    mode: 'onChange',
  });

  const file = watch('file') || {};
  const file_json = stringifyFile(file[0]);

  // ---

  const tableData = useRef<any>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // ---

  const [
    getSettingsDiff,
    {
      error: errorGetSettingsDiff,
      data: dataGetSettingsDiff,
      isLoading: isLoadingGetSettingsDiff,
      reset: resetGetSettingsDiff,
    },
  ] = useGetSettingsDiffMutation();

  const [
    applySettingsImport,
    {
      error: errorApplySettingsImport,
      data: dataApplySettingsImport,
      isLoading: isLoadingApplySettingsImport,
      reset: resetApplySettingsImport,
    },
  ] = useApplySettingsImportMutation();

  const data = useMemo(() => dataGetSettingsDiff?.data ?? [], [dataGetSettingsDiff]);

  const messages = [
    errorGetSettingsDiff?.messageData,
    errorApplySettingsImport?.messageData,
    dataApplySettingsImport?.messageData,
  ];

  // ---

  const handleImportSettings = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file[0]);
    await getSettingsDiff(formData);
  };

  // ---

  const onSelectedRowIdsChange = (selectedRowIds, data) => {
    resetApplySettingsImport();
    const isSelectedArr: any[] = [];

    tableData.current = data.filteredFlatRows
      ?.filter((i) => {
        if (selectedRowIds?.[i.id]) {
          isSelectedArr.push(i);
        }
        return !!selectedRowIds?.[i.id];
      })
      ?.map((i) => {
        const original = { ...i.original ?? {} };
        const idArr = (i.id ?? '').split('.');
        const parentId = idArr.slice(0, -1).join('.');
        const isTemplateObject = idArr[0] == 1 && !!i.original?.subRows?.length;
        const isNeedChanged = i.original?.subRows
          ?.every(({ added, changed, removed }) => !added && !changed && removed);

        delete original.subRows;

        if (isTemplateObject && isNeedChanged && !original.added) {
          const parentSubRows = data.filteredFlatRows.find((item) => item.id === i.id)?.subRows ?? [];
          const isSelectedFiltered = isSelectedArr.filter((selectItem) => selectItem.id.startsWith(parentId) && selectItem.id.split('.').length === 3);
          const changed = (parentSubRows.length !== isSelectedFiltered.length) && (isSelectedFiltered.length > 0);
          const removed = parentSubRows.length === isSelectedFiltered.length;

          return {
            flatId: i.id,
            parentId,
            depth: i.depth,
            ...original,
            changed,
            removed,
          };
        }

        return {
          flatId: i.id,
          parentId,
          depth: i.depth,
          ...original,
        };
      })
      ?.sort((a, b) => a.depth - b.depth);

    setIsDisabled(!tableData.current?.length);
  };

  const handleClickSave = async () => {
    const response: Record<string, any> = await applySettingsImport({ data: nest(tableData.current) });

    if (!Boolean(response.error)) {
      resetApplySettingsImport();
      resetGetSettingsDiff();
      tableData.current = [];
    }
  };

  return (
    <div className="table-loader import-tab">
      <ResponseNotificationByRequestList {...{ messagesValues: messages }} />

      {!Boolean(data.length) && <form className='wrapper-form' onChange={() => resetGetSettingsDiff()}>
        <div className='wrapper-file-upload'>
          <div className="file">
            <div>
              <label className={errors?.file && 'error'}>
                <input
                  id="file"
                  disabled={isLoadingGetSettingsDiff || isLoadingApplySettingsImport}
                  type="file"
                  accept=".json"
                  multiple={false}
                  {...register('file', {
                    validate: (value) => {
                      if (value && value[0] && value[0].type !== 'application/json') {
                        return 'We only support application/json files';
                      }
                      if (value && value[0] && value[0].size > 5242880) {
                        return 'The file is too large';
                      }
                    },
                  })}
                />
                <span className='button'>Browse files</span>
              </label>
            </div>
            <div>
              <span className='file-custom-name'>
                {file_json?.name ? file_json?.name : 'No file selected for this field'}
              </span>
            </div>
          </div>

          <p>JSON file, up to 5MB</p>
          {errors?.file && <span className='error'>{errors?.file?.message?.toString()}</span>}
        </div>

        <div className="wrapper-buttons">
          <Button
            onClick={handleSubmit(handleImportSettings)}
            className="primary upload"
            disabled={!Boolean(file_json?.name)}
            isLoading={isLoadingGetSettingsDiff || isLoadingApplySettingsImport}
          >
            Import settings
          </Button>
        </div>
      </form>}

      {Boolean(data.length) && <>
        <div className='flex-buttons'>
          <div>
            <Button
              type="button"
              className='primary'
              onClick={handleClickSave}
              disabled={isDisabled}
              isLoading={isLoadingApplySettingsImport}
            >
              Save
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={() => {
                resetApplySettingsImport();
                resetGetSettingsDiff();
              }}
              disabled={isLoadingGetSettingsDiff || isLoadingApplySettingsImport}
            >
              Discard changes
            </Button>
          </div>
        </div>

        <BasicTable
          columns={columns}
          data={data}
          paginationable={false}
          isDisabledAllFields={isLoadingGetSettingsDiff || isLoadingApplySettingsImport}
          manualPagination
          selectSubRows={false}
          onSelectedRowIdsChange={onSelectedRowIdsChange}
        />
      </>}

    </div>
  );
};

export default ImportTab;
