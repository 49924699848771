/* eslint-disable max-len */
import { WebDocumentRoutes } from '../../../submodules/common/enums/routes/web-document-routes.enum';
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';
import { IWebDocumentDTO } from '../../../submodules/common/interfaces/dto/web-document/iweb-document.interface';
import { IGetWebDocumentsRequestDTO } from '../../../submodules/common/interfaces/dto/web-document/iget-web-documents-request.interface';
import { IUpdateWebDocumentDTO } from '../../../submodules/common/interfaces/dto/web-document/iupdate-web-document.interface';

import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesDocuments } from './utils';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { ESuccessMessages } from '../../../pages/Documents/utils';

export const documentsApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<{ data: IWebDocumentDTO[], totalCount: number, initialFetching: boolean }, { body: IGetWebDocumentsRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${WebDocumentRoutes.BasePrefix}/${WebDocumentRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<IWebDocumentDTO>> & { initialFetching: boolean }) => {
        return {
          data: response.data.items,
          totalCount: response.data.totalCount,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesDocuments,
    }),
    updateDocument: builder.mutation<IMessageData, { body: IUpdateWebDocumentDTO, id: number }>({
      query: ({ body, id }) => ({
        url: `${WebDocumentRoutes.BasePrefix}/${id}`,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] } };
      },
      invalidatesTags: tagTypesDocuments,
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useUpdateDocumentMutation,
  endpoints,
  internalActions: { resetApiState: resetDocumentsApi },
} = documentsApi;
