/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useCallback, forwardRef, useImperativeHandle, MutableRefObject, useEffect } from 'react';

import useWindowResize from '../../hooks/useWindowResize';
import useWindowScroll from '../../hooks/useWindowScroll';
import { checkIfElementClumped, getRenderCoordinates, getStickyAreaWidth, scrollOverlayIntoView } from '../../utils/tooltipUtils';
import Portal from '../Portal';
import ClipboardComponent from '../ClipboardComponent';
import Button from '../Button';

import Tooltip from './Tooltip';
import TooltipWrapper from './TooltipWrapper';
import { TooltipCoordinates } from './types';
import { DEFAULT_COORDINATES } from './constants';

type TooltipOverlayProps = {
  overlay: React.ReactNode;
  scrollWrapperRef?: MutableRefObject<any> | null;
  isRenderedInTable?: boolean;
};

const TooltipOverlay = forwardRef((
  {
    overlay,
    scrollWrapperRef = null,
    isRenderedInTable = true,
  }: TooltipOverlayProps, ref) => {
  const [isTextClamped, setIsTextClamped] = useState(false);
  const [coordinates, setCoordinates] = useState<TooltipCoordinates>(DEFAULT_COORDINATES);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isInitialScroll, setIsInitialScroll] = useState(false);

  const overlayRef = useRef<HTMLSpanElement>(null);

  const setTooltipDimensions = useCallback(() => {
    const element = overlayRef?.current;
    if (element) {
      const { width } = element.getBoundingClientRect();
      setCoordinates({
        ...getRenderCoordinates(element),
        overlayWidth: width,
      });
    }
  }, []);

  const openTooltip = useCallback((event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (scrollWrapperRef) {
      const wrapperChildren = isRenderedInTable ?
        Array.from(scrollWrapperRef?.current.children[0].children[0].children[0].children) :
        null;
      const wrapperStickyAreaWidth = wrapperChildren ? getStickyAreaWidth(wrapperChildren) : 0;
      scrollOverlayIntoView(overlayRef?.current, scrollWrapperRef?.current, wrapperStickyAreaWidth);
      setIsInitialScroll(true);
    } else {
      setTooltipDimensions();
      setIsTooltipOpen(true);
    }
  }, []);

  const closeTooltip = useCallback(() => {
    setIsTooltipOpen(false);
    setIsTextClamped(false);
  }, []);

  const checkIfOverlayClamped = useCallback(() => {
    const element = overlayRef?.current;
    if (element) {
      setIsTextClamped(checkIfElementClumped(element));
    }
  }, []);

  useImperativeHandle(ref, () => ({
    closeTooltip,
  }));
  useWindowResize(closeTooltip);
  useWindowScroll(closeTooltip);
  useEffect(() => {
    if (isInitialScroll) {
      setTooltipDimensions();
      setIsTooltipOpen(true);
      setIsInitialScroll(false);
    }
  }, [isInitialScroll]);

  return (
    <TooltipWrapper
      overlay={
        <span className='line-clamp' ref={overlayRef}>
          {overlay}
        </span>
      }
      onClick={openTooltip}
      onMouseEnter={checkIfOverlayClamped}
      onMouseLeave={() => !isTooltipOpen && setIsTextClamped(false)}
      isClamped={isTextClamped}
    >
      {isTooltipOpen && (
        <Portal>
          <Tooltip
            coordinates={coordinates}
            onClose={closeTooltip}
          >
            <ClipboardComponent
              callback={closeTooltip}
            >
              <div className='tooltip-content'>{overlay}</div>

              <div className='hr'>
                <Button className='copy tooltip-button'>
                  Copy
                </Button>
              </div>
            </ClipboardComponent>
          </Tooltip>
        </Portal>
      )}
    </TooltipWrapper>
  );
});

export default TooltipOverlay;
