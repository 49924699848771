import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EMessageType } from '../../types/messagesTypes';
import { TResponseNotificationByRequestList } from './types';
import './style.scss';

interface IProps {
  messageType: string,
  messages: Array<string | ReactElement>,
  delay?: number,
  successMessage?: string,
  params?: IParams,
  hideMessageType?: string[],
  additionalClass?: string,
  defaultErrorText?: string,
  isDefaultErrorText?: boolean | undefined,
  isControl?: boolean,
  isMarkHeader?: boolean,

  children?: ReactElement,
}
interface IParams {
  [EMessageType.ERROR]?: number,
  [EMessageType.SUCCESS]?: number,
  [EMessageType.WARNING]?: number,
  [EMessageType.INFO]?: number,
}
export const ResponseNotificationByRequest = ({
  messageType,
  additionalClass = '',
  defaultErrorText = 'Error code:',
  isDefaultErrorText = true,
  messages,
  delay = 2000,
  params = {},
  hideMessageType = [],
  isControl = false,
  isMarkHeader = true,
  children,
}: IProps) => {
  const [visible, setVisible] = useState(true);

  const getDelay = () => {
    if (!!Object.keys(params) && messageType in params) {
      return params[messageType];
    }
    return delay;
  };

  useEffect(() => {
    if (isControl) {
      setVisible(true);
    }
    const _delay = getDelay();
    if (!!_delay) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, _delay);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isControl ? messages : null]);

  const Message = (
    {
      typeMessage,
      messages,
      additionalClass,
    }: {
      typeMessage: string,
      messages: Array<string | ReactElement>,
      additionalClass: string,
      isMarkHeader: boolean,
    }) => {
    const { t, i18n } = useTranslation();
    const errorText = (typeMessage === EMessageType.ERROR && isDefaultErrorText) ? defaultErrorText : '';
    return (
      <div className={`alert-inline ${typeMessage} ${additionalClass}`}>
        {messages.map((el, i) => {
          if (i % 2 === 0) {
            return <p key={i} className={classNames({ bold: isMarkHeader })}>
              {errorText} {i18n.exists(`${typeMessage}.${el}`) ? t(`${typeMessage}.${el}`) : el}</p>;
          }
          return <p key={i}>{i18n.exists(`${typeMessage}.${el}`) ? t(`${typeMessage}.${el}`) : el}</p>;
        })}
        {children}
      </div>
    );
  };

  return (
    <>
      {visible && !hideMessageType.includes(messageType) &&
        <Message messages={messages} typeMessage={messageType}
          additionalClass={additionalClass} isMarkHeader={isMarkHeader} />}
    </>
  );
};

export const ResponseNotificationByRequestList = (props: TResponseNotificationByRequestList) => {
  const { messagesValues, additionalClass = '', children } = props;
  const isMessages = !!messagesValues?.some((el) => !!el?.messages?.length);

  return (
    <>
      <div className={`wrapper-alert-inline ${additionalClass}`}>
        {isMessages &&
          !!messagesValues?.length &&
          messagesValues?.map((m, i) => {
            if (!!m?.messages?.length) {
              return (
                <ResponseNotificationByRequest
                  key={i}
                  messageType={m?.messageType}
                  messages={m?.messages}
                  params={{ [EMessageType.ERROR]: 0, [EMessageType.WARNING]: 0, ...m?.params }}
                  isControl={true}
                  isMarkHeader={m.isMarkHeader}
                  defaultErrorText={m?.defaultErrorText}
                >
                  {children}
                </ResponseNotificationByRequest>
              );
            }
          })
        }
      </div>
    </>
  );
};
