import { LegacyRef, useRef } from 'react';

/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
*/

export const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.classList.add('textAreaCopyToClipboard');
  textArea.value=text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
};

const useCopy = (callback?: () => void): [LegacyRef<HTMLDivElement>, (event: React.SyntheticEvent) => void] => {
  const ref = useRef<HTMLDivElement>({} as HTMLDivElement);

  const handleCopy = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
    const content = '' + ref?.current?.textContent?.trim();

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      unsecuredCopyToClipboard(content);
    }

    if (callback) {
      callback();
    }
  };

  return [ref, handleCopy];
};

export default useCopy;
