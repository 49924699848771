/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTable, usePagination, useRowSelect, useFlexLayout, useSortBy, useFilters, useGlobalFilter, useExpanded, Column } from 'react-table';
import { useSticky } from 'react-table-sticky';
import ClearIndicator from '../ClearIndicator';
import Select from 'react-select';
import './table.scss';
import classnames from 'classnames';
import { DOTS, usePagination as usePaginationRange } from '../PaginationTable/usePagination';
import DefaultColumnFilter from '../Table/DefaultColumnFilter';
import TooltipOverlay from '../TooltipOverlay';
import { customReducer, getMainRowById, getMainRowId, IContentProps, isRowParent, selectData } from './utils';
import { useVirtualizer } from '@tanstack/react-virtual';
import TableCheckbox from '../Table/TableCheckbox';
import { isStringMatching } from '../../utils/systemUtils';
import ReactResizeDetector from 'react-resize-detector';

const TbodyRow = ({
  row, rowProps, renderRowSubComponent, visibleColumns,
  expanded, isRowLoading, measureElement, getTableBodyProps,
  tooltipRef, scrollWrapperRef, className = '', needTBody = false, index, selectedRowIds, selectOnlyByRowIds,
}) => {
  const innerComponent = (
    <>
      <tr
        {...rowProps}
        data-selected={selectOnlyByRowIds ? !!selectedRowIds?.[row.id] : row.isSelected}
        data-not_registered={row.original?.registered === 'NO'}
        data-disabled={row.original?.isDisabled}
        data-depth={row.depth}
        className={className}
        data-error-row={!!row.original?.isRowError}
        data-warning-row={!!row.original?.isRowWarning}

        data-removed={!!row.original?.removed}
        data-added={!!row.original?.added}
        data-changed={!!row.original?.changed}
      >
        {row.cells.map(
          (cell: {
            getCellProps: () => JSX.IntrinsicAttributes &
              React.ClassAttributes<HTMLTableDataCellElement> &
              React.TdHTMLAttributes<HTMLTableDataCellElement>;
            render: (arg0: string, arg1?: {
              isExpanded: boolean,
              isRowLoading: boolean,
            }) => boolean | React.ReactFragment | React.ReactChild |
              React.ReactPortal | null | undefined;
            column: any,
            value: any
          }, i: number
          ) => {
            return (
              <td
                key={i}
                className={cell.column.className}
                {...cell.getCellProps()}
              >
                {['radio', 'expander', 'selection'].includes(cell.column.id) ?
                  cell.render('Cell', {
                    isExpanded: !!expanded[row.id],
                    isRowLoading: !!isRowLoading?.[row.id],
                  }) :
                  cell.column.onColumnClick ? (
                    <div className='wrapper-cell with-modal'
                      onClick={() => cell.column.onColumnClick({
                        paramName: cell.column.Header,
                        paramValue: cell.value,
                      })}>
                      {cell.column.disableTooltip ? cell.render('Cell') :
                        <TooltipOverlay
                          overlay={cell.render('Cell')}
                          ref={tooltipRef}
                          scrollWrapperRef={scrollWrapperRef}
                        />}
                    </div>
                  ) : (
                    <div className='wrapper-cell'>
                      {cell.column.disableTooltip ? cell.render('Cell') :
                        <TooltipOverlay
                          overlay={cell.render('Cell')}
                          ref={tooltipRef}
                          scrollWrapperRef={scrollWrapperRef}
                        />}
                    </div>
                  )
                }
              </td>
            );
          })}
      </tr>

      {row.isExpanded && !!renderRowSubComponent &&
        renderRowSubComponent({ row, rowProps, visibleColumns, tooltipRef, tableRef: scrollWrapperRef })}
    </>
  );

  return needTBody ? (
    <tbody {...getTableBodyProps()} ref={measureElement} data-index={index} data-depth={row.depth}>
      {innerComponent}
    </tbody>
  ) : innerComponent;
};

const BasicTable = forwardRef(({
  columns,
  data,
  className,
  selectable = false,
  paginationable = true,
  manualPagination = false,
  isDisabledAllFields = false,
  isAlwaysAllowCheckbox = false,
  onSelectedRowIdsChange,
  onTableStateChange,
  onTableSortByChange,
  showSelectedItems = false,
  showSelectedItemsWithTotal = false,
  updateTableRowData,
  updateColumnData,
  updateTableData,
  filter: controlledFilter = [],
  sort: controlledSort = [],
  expanded: controlledExpanded = {},
  globalFilterValue,
  globalFilterFunction,
  controlledPageIndex = 0,
  selectedIds = {},
  pageSize: controlledPageSize = 10,
  renderRowSubComponent,
  noDataTextArr = ['No matching records found'],
  scrollPositionInitial = { left: 0, top: 0 },
  onScrollPositionChange,
  isRowLoading,
  isUseRowSelected = true,
  selectOnlyByRowIds = false,
  manualTableStateChange = false,

  selectSubRows = true,
  expandSubRows = true,
  paginateExpandedRows = true,
  autoResetSelectedRows = true,
  autoResetExpanded = true,
  autoResetPage = true,
  isAllRowsSelectedByDefault = false,
}: IContentProps, ref: any): JSX.Element => {
  const [rowFetching, setRowFetching] = useState<null | string>(null);

  const [, setState] = React.useState(false);

  const tooltipRef = useRef<HTMLElement | any>(null);
  const tableRef = useRef<HTMLElement | any>(null);
  const preFilteredRowsRef = useRef<any[]>([]);
  const rowVirtualizerRef = useRef<any>(null);

  const columnsToRender = useMemo(
    () => typeof columns === 'function' ? columns(tooltipRef, tableRef) : columns,
    [columns]
  ) as readonly Column<any>[];

  const handleSetRowLoading = (rowId: string | null) => {
    setRowFetching(rowId);
  };

  const localDateFilterFn = (rows, id, filterValue) => {
    const startDate = new Date(filterValue?.slice(0, filterValue?.indexOf(';')));
    const endDate = new Date(filterValue?.slice(filterValue?.indexOf(';') + 1));
    return rows.filter((a) => {
      const date = new Date(a.values[id[0]]);
      return date >= startDate && date <= endDate;
    });
  };

  const selectFilterFn = (rows, id, filterValue) => {
    return rows.filter((a) => {
      return a.values[id[0]]?.toString() === filterValue?.toString();
    });
  };

  const filterSubRows = (rows, id, filterValue) => {
    const rowFilter = rows.filter((el) => {
      if (!isRowParent(el.id)) {
        const parentEl = getMainRowById(preFilteredRowsRef.current ?? [], getMainRowId(el.id));
        return isStringMatching(parentEl?.original?.[id[0]] ?? '', filterValue?.trim() ?? '');
      }
      return isStringMatching(el?.original?.[id[0]] ?? '', filterValue?.trim() ?? '');
    });

    return rowFilter;
  };

  const filterTypes = {
    localDate: localDateFilterFn,
    select: selectFilterFn,
    filterSubRows,
  };

  const globalFilterHandle = (rows, ids, query) => {
    return globalFilterFunction ? globalFilterFunction(rows, ids, query) : rows;
  };

  const forceUpdate = () => {
    setState((prev) => !prev);
  };

  const {
    visibleColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsSelected,
    filteredFlatRows,
    selectedFlatRows,
    expandedRows,
    setGlobalFilter,
    filteredRows,
    rowsById,
    preFilteredRows,
    state: {
      pageIndex,
      pageSize,
      selectedRowIds,
      filters,
      sortBy,
      expanded,
    },
  } = useTable(
    {
      columns: columnsToRender,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      filterTypes,
      isDisabledAllFields,
      manualPagination: manualPagination,
      updateTableRowData,
      updateColumnData,
      updateTableData,
      rowFetching,
      forceUpdate,
      setRowFetching: handleSetRowLoading,
      stateReducer: (newState, action, previousState) => customReducer(newState, action,
        previousState, manualTableStateChange ? onTableStateChange : () => null),
      initialState: {
        filters: controlledFilter,
        globalFilter: globalFilterValue,
        pageIndex: controlledPageIndex,
        selectedRowIds: selectedIds,
        pageSize: controlledPageSize,
        sortBy: controlledSort,
        expanded: controlledExpanded,
      },
      globalFilter: globalFilterHandle,
      selectSubRows,
      expandSubRows,
      paginateExpandedRows,
      autoResetSelectedRows,
      autoResetExpanded,
      autoResetPage,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    isUseRowSelected ? useRowSelect : () => null,
    useFlexLayout,
    useSticky,
    (hooks) => {
      selectable && hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ toggleRowSelected, page }) => {
            // @ts-ignore:next-line
            const isDisabledRows = page.filter((row) => row.original.disabled === true).length;
            // @ts-ignore:next-line
            const isEnabledRows = page.filter((row) => row.original.disabled !== true).length;

            const modifiedOnChange = (event) => {
              page.forEach((row) => {
                // @ts-ignore:next-line
                !row.original.disabled && toggleRowSelected(row.id, event.currentTarget.checked);
              });
            };

            const isSelectedRows = page.filter((row) => row.isSelected === true).length;
            const indeterminate = isSelectedRows < page.length && isSelectedRows !== 0;
            const isChecked = (isSelectedRows === isEnabledRows) && !!isSelectedRows && !!isEnabledRows;

            return (
              <TableCheckbox
                onChange={modifiedOnChange}
                checked={isChecked}
                disabled={isAlwaysAllowCheckbox ? false :
                  (page.length === isDisabledRows || isDisabledAllFields || !!rowFetching)}
                indeterminate={indeterminate}
              />
            );
          },
          Cell: ({ row }) => {
            return (
              <TableCheckbox
                {...row.getToggleRowSelectedProps()}
                disabled={isAlwaysAllowCheckbox ? false :
                  // @ts-ignore:next-line
                  (row.original.disabled || isDisabledAllFields || !!rowFetching)}
              />
            );
          },
          sticky: 'left',
          width: 40,
          maxWidth: 40,
          className: 'checkbox-column',
        },
        ...columns,
      ]);
    }
  );

  preFilteredRowsRef.current = preFilteredRows;
  useImperativeHandle(ref, () => ({
    getRowById: (id) => rowsById[id],
    gotoPage,
    preFilteredRows,
    resetSelectedRow() {
      toggleAllRowsSelected(false);
    },
    rowVirtualizer: rowVirtualizerRef.current,
  }));

  const [scrollPosition, setScrollPosition] = useState({ left: 0, top: 0 });
  const scrollHandler = () => {
    clearTimeout(tableRef.current.scrollEndTimer);
    tableRef.current.scrollEndTimer = setTimeout(() => {
      setScrollPosition({
        left: tableRef?.current?.scrollLeft ?? 0,
        top: tableRef?.current?.scrollTop ?? 0,
      });
    }, 200);

    tableRef.current.dataset.scrollEnd = tableRef.current.offsetWidth +
      tableRef.current.scrollLeft < tableRef.current.scrollWidth;
    tableRef.current.dataset.scrollStart = tableRef.current.scrollLeft != 0;
    tooltipRef?.current?.closeTooltip();
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('resize', scrollHandler);

    if (isAllRowsSelectedByDefault) {
      toggleAllRowsSelected(true);
    }

    return () => window.removeEventListener('resize', scrollHandler);
  }, []);

  const total = filteredRows.length;
  const startNumber = (Number(pageIndex)) * Number(pageSize) + 1;
  const startNumberPageSize = startNumber + pageSize - 1;
  const endNumber = startNumberPageSize > total ? total : startNumberPageSize;

  const paginationRange = usePaginationRange({
    currentPage: pageIndex + 1,
    totalCount: total,
    siblingCount: 1,
    pageSize,
  }) || [];

  const rowVirtualizer = useVirtualizer({
    count: page.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 45,
    overscan: 20,
    initialOffset: scrollPositionInitial.top,
  });

  rowVirtualizerRef.current = rowVirtualizer;
  useVirtualizer({
    horizontal: true,
    count: visibleColumns.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 300,
    initialOffset: scrollPositionInitial.left,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const virtualRowsLength = virtualRows.length;
  const totalSize = rowVirtualizer.getTotalSize();
  const paddingTop = virtualRowsLength > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRowsLength > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useEffect(() => {
    !!onSelectedRowIdsChange && onSelectedRowIdsChange(selectedRowIds, {
      pageIndex,
      pageSize,
      selectedRowIds,
      filters,
      sortBy,
      expanded,
      filteredFlatRows,
    });
  }, [selectedRowIds]);

  useEffect(() => {
    setGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  useEffect(() => {
    (!!onTableStateChange && !manualTableStateChange) && onTableStateChange({
      filters,
      sortBy,
      selectedFlatRows,
      expandedRows,
    });
  }, [filters, sortBy, selectedFlatRows, expandedRows]);

  useEffect(() => {
    !!onTableSortByChange && onTableSortByChange(sortBy);
  }, [sortBy]);

  useEffect(() => {
    !!onScrollPositionChange && onScrollPositionChange(scrollPosition);
  }, [scrollPosition]);

  // ---

  const [isNotOneLine, setIsNotOneLine] = useState<boolean>(false);
  const onResize = (_, height) => {
    if (height > 36) {
      !isNotOneLine && setIsNotOneLine(true);
    } else {
      isNotOneLine && setIsNotOneLine(false);
    }
  };


  return (
    <>
      {showSelectedItems &&
        <p className='text'>
          Selected: <strong>{Object.keys(selectedRowIds).length}</strong>
        </p>
      }

      {showSelectedItemsWithTotal &&
        <p className='text total'>
          <strong>{Object.keys(selectedRowIds).length}</strong> item(s) selected from {total}
        </p>
      }

      <div className="table">

        <div className='wrapper-sticky-pagination'>
          <div
            ref={tableRef}
            className="table-responsive"
            data-scroll-start={false}
            data-scroll-end={false}
            onScroll={scrollHandler}
            style={{ height: 200 }}
          >

            <table {...getTableProps()} className={classnames(className)}>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 6,
                }}
              >
                {headerGroups.map((headerGroup: any, i: number) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, i: number) => (
                      <th
                        key={i}
                        {...column.getHeaderProps()}
                        className={column.className}
                      >
                        <div className='wrapper-cell'>
                          {column.canSort ? (
                            <>
                              <span className="can-sort">
                                {!!column.disableTooltipHeader ? (
                                  <span className='line-clamp'>{column.render('Header').split('<br/>').join('\n')}</span>
                                ) : (
                                  <TooltipOverlay
                                    overlay={
                                      typeof column.render('Header') === 'string' ?
                                        column.render('Header').split('<br/>').join('\n') :
                                        column.render('Header')
                                    }
                                    ref={tooltipRef}
                                    scrollWrapperRef={tableRef}
                                  />
                                )}
                                <button
                                  {...column.getSortByToggleProps()}
                                  title=''
                                  className={column.isSorted ? column.isSortedDesc ? 'table-arrow-down' : 'table-arrow-up' : 'default-arrow-table'}
                                  disabled={isDisabledAllFields || !!rowFetching}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              {typeof column.render('Header') === 'string' ?
                                <TooltipOverlay
                                  overlay={column.render('Header').split('<br/>').join('\n')}
                                  ref={tooltipRef}
                                  scrollWrapperRef={tableRef}
                                /> :
                                column.render('Header')}
                            </>
                          )}
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              {page && page.length ? (
                <>
                  <tbody {...getTableBodyProps()}>
                    {paddingTop > 0 && (
                      <tr>
                        <td style={{ height: `${paddingTop}px` }} />
                      </tr>
                    )}
                  </tbody>

                  {virtualRows.map((virtualRow) => {
                    const row = page[virtualRow.index];
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <TbodyRow
                        key={row.id}
                        row={row}
                        rowProps={rowProps}
                        renderRowSubComponent={renderRowSubComponent}
                        visibleColumns={visibleColumns}
                        expanded={expanded}
                        isRowLoading={isRowLoading}
                        measureElement={rowVirtualizer.measureElement}
                        getTableBodyProps={getTableBodyProps}
                        tooltipRef={tooltipRef}
                        scrollWrapperRef={tableRef}
                        needTBody={true}
                        index={virtualRow.index}
                        selectedRowIds={selectedRowIds}
                        selectOnlyByRowIds={selectOnlyByRowIds}
                      />
                    );
                  })}

                  <tbody {...getTableBodyProps()}>
                    {paddingBottom > 0 && (
                      <tr>
                        <td style={{ height: `${paddingBottom}px` }} />
                      </tr>
                    )}
                  </tbody>
                </>
              ) : (
                <tbody>
                  <tr className='no-border'>
                    <td colSpan={columns?.length}>

                      <div className='no-data'>
                        <span></span>
                        {!!noDataTextArr?.length &&
                          noDataTextArr.map((x, i) => (<p key={i}>{x}</p>))
                        }
                      </div>

                    </td>
                  </tr>
                </tbody>
              )}

            </table>

          </div>

          {paginationable && <div className="pagination">

            <ReactResizeDetector onResize={onResize} handleWidth={false}>
              <div className={classnames('flex-box-pagination', {isNotOneLine})}>
                <div>
                  <p>
                  Items shown:
                    {' '}
                    {data?.length > 0 ? (
                      <>
                        <strong>{
                          isNaN(startNumber) ? 0 : startNumber
                        }</strong> - <strong>{
                          isNaN(endNumber) ? 0 : endNumber
                        }</strong>
                      </>
                    ) : (
                      <>
                        <strong>0</strong> - <strong>0</strong>
                      </>
                    )}
                    {' '}
                  out of <strong>{total}</strong>
                  </p>
                </div>
                <div><span className='delimiter' /></div>
                <div>
                  <div className='wrapper-per-page'>
                    <div>
                      <p>Items per page:</p>
                    </div>
                    <div>
                      <Select
                        isDisabled={isDisabledAllFields || !!rowFetching}
                        className='wrapper_select_single wrapper_select_inline'
                        classNamePrefix='react-select_single'
                        options={selectData}
                        value={{ value: pageSize, label: String(pageSize) }}
                        onChange={(option: any) => setPageSize(Number(option.value))}
                        menuPosition='absolute'
                        blurInputOnSelect
                        menuPlacement='top'
                        components={{ ClearIndicator }}
                        isSearchable={false}
                        isClearable={false}
                      />
                    </div>
                  </div>
                </div>
                <div>

                  {(pageOptions.length > 1) && <ul className='pagination-container'>
                    <li key={'onStart'}>
                      <button
                        onClick={() => gotoPage(0)}
                        className={classnames('pagination-item', { disabled: !canPreviousPage || !!rowFetching || isDisabledAllFields })}
                        disabled={!canPreviousPage || !!rowFetching || isDisabledAllFields}
                      >
                        <span className="arrow start" />
                      </button>
                    </li>
                    <li key={'onPrevious'}>
                      <button
                        onClick={() => previousPage()}
                        className={classnames('pagination-item', { disabled: !canPreviousPage || !!rowFetching || isDisabledAllFields })}
                        disabled={!canPreviousPage || !!rowFetching || isDisabledAllFields}
                      >
                        <span className="arrow left" />
                      </button>
                    </li>
                    {paginationRange.map((pageNumber, index) => {
                      if (pageNumber === DOTS) {
                        return (
                          <li key={`dots-${index}`}>
                            <div className="pagination-item dots">
                              <span>&#8230;</span>
                            </div>
                          </li>
                        );
                      }
                      return (
                        <li key={pageNumber}>
                          <button
                            onClick={() => gotoPage(Number(pageNumber) - 1)}
                            className={classnames('pagination-item', {
                              selected: pageNumber === pageIndex + 1,
                              disabled: !!rowFetching || isDisabledAllFields,
                            })}
                            disabled={!!rowFetching || isDisabledAllFields}
                          >
                            <span>{pageNumber}</span>
                          </button>
                        </li>
                      );
                    })}
                    <li key={'onNext'}>
                      <button
                        onClick={() => nextPage()}
                        className={classnames('pagination-item', { disabled: !canNextPage || !!rowFetching || isDisabledAllFields })}
                        disabled={!canNextPage || !!rowFetching || isDisabledAllFields}
                      >
                        <span className="arrow right" />
                      </button>
                    </li>
                    <li key={'onEnd'}>
                      <button
                        onClick={() => gotoPage(pageCount - 1)}
                        className={classnames('pagination-item', { disabled: !canNextPage || rowFetching || isDisabledAllFields })}
                        disabled={!canNextPage || !!rowFetching || isDisabledAllFields}
                      >
                        <span className="arrow end" />
                      </button>
                    </li>
                  </ul>}

                </div>
              </div>
            </ReactResizeDetector>

          </div>}

        </div>
      </div>
    </>
  );
});

export default BasicTable;
