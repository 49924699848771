import { POSITION } from '../../types/tooltipOverlayTypes';

export const DEFAULT_COORDINATES = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: 0,
  overlayWidth: 0,
  position: 'top' as typeof POSITION[number],
};

export const TOOLTIP_MAX_HEIGHT = 177;
export const TOOLTIP_MAX_WIDTH = 600;
