import { SingleValue } from 'react-select';
import { LlmModel } from '../../submodules/common/enums/common/llm-model.enum';
import { ITypedSelect } from '../../types/selectTypes';

export type TSettings = {
  prompt: string;
  llmModel: SingleValue<ITypedSelect<LlmModel>>;
  llmTemperature: string;
  tokens: string;
  documentRetreiverLimit: string;
};

export const options: ITypedSelect<LlmModel>[] = [
  { label: LlmModel.GPT_3, value: LlmModel.GPT_3 },
  { label: LlmModel.GPT_4, value: LlmModel.GPT_4 },
];

export const settingsDefaultValues: TSettings = {
  prompt: '',
  llmModel: null,
  llmTemperature: '0',
  tokens: '0',
  documentRetreiverLimit: '0',
};

export enum ESuccessMessagesGreetingMessage {
  UPDATE = 'Greeting message has been updated successfully',
}
