import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/scss/index.scss';

import Layout from './layout';
import { PrivateRoute } from './components/PrivateRoute';
import { useAuthSetting } from './hooks/useAuthSetting';

const Login = React.lazy(() => import('./pages/Login'));
const QuestionAnswer = React.lazy(() => import('./pages/QuestionAnswer'));
const DocumentsPage = React.lazy(() => import('./pages/Documents'));
const SessionsChats = React.lazy(() => import('./pages/SessionsChats'));
const SessionChat = React.lazy(() => import('./pages/SessionChat'));
const Settings = React.lazy(() => import('./pages/Settings'));
const VersionPage = React.lazy(() => import('./pages/Version'));
const TemplateQuestions = React.lazy(() => import('./pages/TemplateQuestions'));

const ChatAdminNew = React.lazy(() => import('./pages/ChatAdmin'));

import './i18n/config';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ExportImportSettings from './pages/ExportImportSettings';
import useBeforeRender from './hooks/useBeforeRender';
import { broadcastChannel } from '.';
import { broadcastChannelServices } from './events/broadcastChannel';

import { store } from './redux/store';
import { useMaintenance } from './hooks/useTypeSelectorHooks/useMaintenance';
import { EStatus, socketMaintenance } from './api/apiMaintenanceUtils';
import { setStatusOff, setStatusOn } from './redux/reducers/maintenanceSlice';
import Maintenance from './pages/Maintenance';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const { status } = useMaintenance();
  const { dispatch } = store;

  const { initialAuthSetting } = useAuthSetting();
  const to = '/login';


  if (process.env.NODE_ENV === 'development') {
    useBeforeRender(() => {
      window.addEventListener('error', (e) => {
        if (e) {
          const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div',
          );
          const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay',
          );
          if (resizeObserverErr) {
            resizeObserverErr.className = 'hide-resize-observer';
          }
          if (resizeObserverErrDiv) {
            resizeObserverErrDiv.className = 'hide-resize-observer';
          }
        }
      });
    }, []);
  }

  useEffect(() => {
    broadcastChannelServices();

    socketMaintenance.open();
    socketMaintenance.on('maintenance', (data) => {
      if (data?.status === EStatus.ON) {
        dispatch(setStatusOn());
      } else {
        dispatch(setStatusOff());
      }
    });

    return () => {
      broadcastChannel.close();
      socketMaintenance.close();
    };
  }, []);

  if (status) {
    return <Maintenance />;
  }

  return (
    <MsalProvider instance={pca}>
      {initialAuthSetting &&
        <div className='content'>
          <Routes>
            <Route
              element={
                <React.Suspense fallback={null}>
                  <Login />
                </React.Suspense>
              }
              path='/login'
            />

            <Route
              element={
                <React.Suspense fallback={null}>
                  <VersionPage />
                </React.Suspense>
              }
              path='/version'
            />

            <Route path='/qa' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><QuestionAnswer /></React.Suspense>
                }
              />
            </Route>

            <Route path='/sessions-chat' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><SessionsChats /></React.Suspense>
                }
              />
            </Route>

            <Route path='/sessions-chat/:id' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}><SessionChat /></React.Suspense>
                }
              />
            </Route>

            <Route path='/documents' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <DocumentsPage />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/chat-admin' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <ChatAdminNew />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/settings' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <Settings />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/export-import-settings' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <ExportImportSettings />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='/template-questions' element={
              <PrivateRoute to={to}>
                <Layout />
              </PrivateRoute>
            }>
              <Route
                index
                element={
                  <React.Suspense fallback={null}>
                    <TemplateQuestions />
                  </React.Suspense>
                }
              />
            </Route>

            <Route path='*' element={<Navigate replace to={to} />} />
          </ Routes>
        </div>
      }
    </MsalProvider>
  );
}

export default App;
