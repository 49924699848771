import { useEffect } from 'react';

const useWindowScroll = (onScroll: () => void) => {
  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('onScroll', onScroll);
    };
  }, [onScroll]);
};

export default useWindowScroll;
