import { Manager } from 'socket.io-client';
import { appConfig } from '../infrastructure/app-config.infrastructure';


const maintenanceUrl = appConfig.AI_API_MAINTENANCE_URL;

const manager = new Manager(maintenanceUrl, {
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ['websocket'],
});

export const socketMaintenance = manager.socket('/');

export enum EStatus {
  ON = 'on',
  OFF = 'off',
}
