/* eslint-disable max-len */
import { IApiResponseGenericDTO } from '../../../submodules/common/interfaces/dto/common/iapi-response.interface';
import { IPaginatedResultDTO } from '../../../submodules/common/interfaces/dto/common/ipaginated-result.interface';

import { TemplateQuestionsRoutes } from '../../../submodules/common/enums/routes/template-questions-routes.enum';
import { IGetTemplateQuestionsDto } from '../../../submodules/common/interfaces/dto/template-questions/iget-template-questions.interface';
import { IGetTemplateQuestionsRequestDTO } from '../../../submodules/common/interfaces/dto/template-questions/iget-template-questions-request.interface';
import { IGetTemplateQuestionsOnLocationDto } from '../../../submodules/common/interfaces/dto/template-questions/iget-template-questions-on-location.interface copy';
import { ICreateTemplateQuestionsOnLocationDto } from '../../../submodules/common/interfaces/dto/template-questions/icreate-chat-location.interface';
import { IUpdateTemplateQuestionsOnLocationDto } from '../../../submodules/common/interfaces/dto/template-questions/iupdate-chat-location.interface';

import { EMethod } from '../utils';
import { adminApi } from './adminApi';
import { tagTypesTemplateQuestions } from './utils';
import { EMessageType, IMessageData } from '../../../types/messagesTypes';
import { ESuccessMessages } from '../../../pages/TemplateQuestions/utils';


export const templateQuestionApi = adminApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateQuestion: builder.query<{ data: IGetTemplateQuestionsDto[], totalCount: number, initialFetching: boolean }, { body: IGetTemplateQuestionsRequestDTO | null }>({
      query: ({ body }) => ({
        url: `${TemplateQuestionsRoutes.BasePrefix}/${TemplateQuestionsRoutes.Search}`,
        method: EMethod.POST,
        body,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IPaginatedResultDTO<IGetTemplateQuestionsDto>> & { initialFetching: boolean }) => {
        return {
          data: response.data.items,
          totalCount: response.data.totalCount,
          initialFetching: response.initialFetching,
        };
      },
      providesTags: tagTypesTemplateQuestions,
    }),
    getTemplateQuestionsById: builder.query<{ data: IGetTemplateQuestionsOnLocationDto, initialFetching: boolean }, { id: string }>({
      query: ({ id }) => ({
        url: `${TemplateQuestionsRoutes.BasePrefix}/${id}`,
        method: EMethod.GET,
      }),
      transformResponse: (response: IApiResponseGenericDTO<IGetTemplateQuestionsOnLocationDto> & { initialFetching: boolean }) => {
        return {
          data: response.data,
          initialFetching: response.initialFetching,
        };
      },
    }),
    createTemplateQuestion: builder.mutation<IMessageData, ICreateTemplateQuestionsOnLocationDto>({
      query: (body) => ({
        url: TemplateQuestionsRoutes.BasePrefix,
        method: EMethod.POST,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.CREATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesTemplateQuestions;
      },
    }),
    editTemplateQuestion: builder.mutation<IMessageData, { body: IUpdateTemplateQuestionsOnLocationDto }>({
      query: ({ body }) => ({
        url: TemplateQuestionsRoutes.BasePrefix,
        method: EMethod.PUT,
        body,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.UPDATE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesTemplateQuestions;
      },
    }),
    deleteTemplateQuestion: builder.mutation<IMessageData, { id: number }>({
      query: ({ id }) => ({
        url: `${TemplateQuestionsRoutes.BasePrefix}/${id}`,
        method: EMethod.DELETE,
      }),
      transformResponse: () => {
        return { messageData: { messageType: EMessageType.SUCCESS, messages: [ESuccessMessages.DELETE] } };
      },
      invalidatesTags: (_result, error) => {
        return !!error ? [] : tagTypesTemplateQuestions;
      },
    }),
  }),
});

export const {
  useGetTemplateQuestionQuery,
  useGetTemplateQuestionsByIdQuery,
  internalActions: { resetApiState: resetTemplateQuestionApi },
  useCreateTemplateQuestionMutation,
  useEditTemplateQuestionMutation,
  useDeleteTemplateQuestionMutation,
} = templateQuestionApi;
