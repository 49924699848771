import React, { ReactNode } from 'react';
import useCopy from '../../hooks/useCopy';

type ClipboardProps = {
  children: ReactNode,
  callback?: () => void;
};

const ClipboardComponent = ({ children, callback }: ClipboardProps) => {
  const [copyElementRef, handleCopy] = useCopy(callback);

  const objData = [{ ref: copyElementRef }, { onClick: handleCopy }];

  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child) && index < objData.length) {
          return React.cloneElement(child, { ...objData[index] });
        }
        return child;
      })}
    </>
  );
};

export default ClipboardComponent;
