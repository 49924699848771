import React from 'react';

const useDetectClickOutside = (handlerOutSide: () => void) => {
  const refElement = React.useRef<HTMLDivElement>(null);
  const checkClickRef = React.useRef<boolean>(false);
  const isScrolled = React.useRef<boolean>(false);

  const handlerMouseDown: (event: Event) =>
    void = (event: Event) => {
      const target = event.target as HTMLDivElement;

      const includeObj = ['portal-root', 'modal-offline', 'notification-stack'];
      const excludeObj = ['#notification-stack .data .btn-flex-group a.btn'];
      const isClickIncludeObj = includeObj.map((i) => !!document.getElementById(i)?.contains(target) ?? false)
        .some((i) => i);
      const isClickExcludeObj = excludeObj.map((i) => Array.prototype.slice.call(document.querySelectorAll(i))
        .some((i) => i.contains(target))).some((i) => i);

      const refEInside = !refElement?.current?.contains(target);
      const isOutsideCLick = refEInside;

      checkClickRef.current = isOutsideCLick && (!isClickIncludeObj || isClickExcludeObj);
    };

  const handlerScroll = () => {
    isScrolled.current = true;
  };

  const handlerMouseUp: (event: Event) =>
    void = () => {
      if (!isScrolled.current && checkClickRef.current) {
        handlerOutSide();
      }
      isScrolled.current = false;
    };

  React.useEffect(() => {
    document.addEventListener('mousedown', handlerMouseDown);
    document.addEventListener('mouseup', handlerMouseUp);
    document.addEventListener('scroll', handlerScroll);
    return () => {
      document.removeEventListener('mousedown', handlerMouseDown);
      document.removeEventListener('mouseup', handlerMouseUp);
      document.removeEventListener('scroll', handlerScroll);
    };
  }, [handlerOutSide]);

  return refElement;
};

export default useDetectClickOutside;
