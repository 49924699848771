import { IUpdateWebDocumentDTO } from '../../submodules/common/interfaces/dto/web-document/iupdate-web-document.interface';
import { IWebDocumentDTO } from '../../submodules/common/interfaces/dto/web-document/iweb-document.interface';

export const prepareDataUpdate = (data: IWebDocumentDTO): { body: IUpdateWebDocumentDTO, id: number } => {
  return { body: { disabled: !data.disabled }, id: data.id };
};

export enum ESuccessMessages {
  UPDATE = 'Document has been updated successfully',
}
