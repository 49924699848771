/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, useMemo } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

import { IGetExportSettings } from '../../../submodules/common/interfaces/dto/settings/iget-export-settings.interface';

import BasicTable from '../../../components/BasicTable';
import { Loader } from '../../../components/loader';
import Button from '../../../components/Button';
import { ResponseNotificationByRequestList } from '../../../components/ResponseNotificationByRequest';

import {
  ESuccessMessagesSettings,
  columns,
} from './utils';
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from '../../../redux/api/adminApi/settingsApi';
import { EMessageType } from '../../../types/messagesTypes';

const ExportTab: React.FC = () => {
  const [query, setQuery] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState<
    Record<string, unknown> | undefined
  >();
  const tableData = useRef<any>([]);

  useEffect(() => {
    setQuery('');
  }, []);

  const {
    isFetching: isGetSettingsFetching,
    data: settingsData,
    initialFetching: isGetSettingsFulfilled,
    error: getSettingsError,
  } = useGetSettingsQuery(
    { query },
    {
      skip: query === null,
      selectFromResult: ({ data, error, status, ...rest }) => {
        return {
          ...rest,
          data: data?.data ?? null,
          initialFetching: status === QueryStatus.fulfilled,
          error: { messageData: error?.messageData },
        };
      },
    }
  );

  const [
    updateSettings,
    { error: updateSettingsError, isLoading: isUpdateSettingsFetching },
  ] = useUpdateSettingsMutation();

  const onSelectedRowIdsChange = (selectedRowIds, data) => {
    tableData.current = data.filteredFlatRows?.map((i) => {
      return {
        id: i.id,
        isSelected: !!selectedRowIds?.[i.id],
        idOriginal: i.original.id,
        depth: i.depth,
        parameters: i.original.parameters,
      };
    });

    setIsDisabled(!tableData.current?.filter((i) => i.isSelected).length);
  };

  const prepareTableData = (): IGetExportSettings => {
    const idsToSkip = new Set(['0', '1', '2']);
    const result: IGetExportSettings = {
      qa: {},
      // qc: {},
      templateURL: {},
      templateQuestions: {},
      // prompts: {},
      // defaultPrompt: false,
      greeting: false,
    };

    tableData.current.forEach((
      { id, idOriginal, isSelected, parameters, depth }:
      { id: string; idOriginal: string; isSelected: boolean; parameters: string; depth: number }
    ) => {
      if (!idsToSkip.has(id)) {
        const parentId = id.split('.')[0];

        switch (parentId) {
          case '0':
            result.qa[idOriginal] = isSelected;
            break;
          // case '1':
          //   if (depth !== 2) {
          //     result.qc[idOriginal] = isSelected;
          //   } else {
          //     result.prompts[idOriginal] = isSelected;
          //   }
          //   break;
          case '1':
            if (depth !== 2) {
              result.templateURL[idOriginal] = isSelected;
            } else {
              result.templateQuestions[idOriginal] = isSelected;
            }
            break;
          case '2':
            // if (parameters === 'Greeting') {
            //   result.greeting = isSelected;
            // } else if (parameters === 'Default Template') {
            //   result.defaultPrompt = isSelected;
            // }
            if (parameters === 'Greeting') {
              result.greeting = isSelected;
            }
            break;
          default:
            break;
        }
      }
    });

    return result;
  };

  const handleClickExport = async () => {
    const preparedData = prepareTableData();

    const response: Record<string, any> = await updateSettings({
      body: preparedData,
    });

    if (!response.error) {
      const dateObj = new Date();
      const timeArr = dateObj.toLocaleTimeString().split(':');
      const dateArr = dateObj.toLocaleDateString('en-US').split('/');
      const fileName = ['Settings', ...dateArr, ...timeArr].join('_');
      const responseDataCopy = { ...response.data };
      delete responseDataCopy.initialFetching;

      const blob = new Blob([JSON.stringify(responseDataCopy)], {
        type: 'application/json',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setUpdateSuccessMessage({
        messageType: EMessageType.SUCCESS,
        messages: [ESuccessMessagesSettings.UPDATE],
      });
    }
  };

  const messages = [
    updateSettingsError?.messageData,
    getSettingsError?.messageData,
    updateSuccessMessage,
  ];

  const isMessageShown = useMemo(() => {
    return messages.some((message) => !!message);
  }, [messages]);

  if (
    (isGetSettingsFetching || !isGetSettingsFulfilled) &&
    !getSettingsError.messageData
  ) {
    return (
      <div className="settings-form">
        <Loader />
      </div>
    );
  }

  return (
    <div className="table-loader export-tab">
      {isMessageShown && (
        <ResponseNotificationByRequestList {...{ messagesValues: messages }} />
      )}

      <div className="wrapper-buttons">
        <Button
          onClick={handleClickExport}
          className="download"
          disabled={isDisabled}
          isLoading={isUpdateSettingsFetching}
        >
          Export selected
        </Button>
      </div>

      <BasicTable
        columns={columns}
        data={settingsData ?? []}
        paginationable={false}
        isDisabledAllFields={true}
        manualPagination
        selectSubRows={false}
        onSelectedRowIdsChange={onSelectedRowIdsChange}
        isAllRowsSelectedByDefault
      />
    </div>
  );
};

export default ExportTab;
