import React, { useEffect, useRef, useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { IDefaultColumnFilter } from '../types';
import './style.scss';

function DefaultColumnFilter({ column: { filterValue, setFilter },
  isDisabledAllFields, rowFetching }: IDefaultColumnFilter) {
  const mounted = useRef(false);
  const [value, setValue] = useState(filterValue || '');

  const handlerValueClear = () => {
    mounted.current && setFilter(undefined);
  };

  const onChange = useAsyncDebounce((i) => {
    mounted.current && setFilter(i || undefined);
  }, 500);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  return (
    <div className='wrapper-input wrapper-input-filter'>
      <input
        autoComplete="off"
        data-empty={!!!value}
        disabled={isDisabledAllFields || !!rowFetching}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      />
      {!!value && <span className='clear-indicator' onClick={handlerValueClear} />}
    </div>
  );
}

export default DefaultColumnFilter;
