import React from 'react';
import Button from '../Button';
import { useActions } from '../../hooks/useActions';
import { useAuth } from '../../hooks/useTypeSelectorHooks/useAuth';
import { AccountTypeAuth } from '../../submodules/common/enums/account/account-type-auth.enum';

const Menu = React.forwardRef<HTMLUListElement, { isOpen: boolean }>((props, ref) => {
  const { isOpen } = props;
  const { logOut } = useActions();
  const { account } = useAuth();

  const handleLogOut = async () => {
    if (account?.typeAuth === AccountTypeAuth.AD) {
      sessionStorage.clear();
    }
    logOut();
  };

  Menu.displayName = 'WrapperMenu';

  return (
    <>
      {isOpen && (
        <ul
          ref={ref}
          className='header-menu'>
          <li>
            <Button
              className='link'
              onClick={handleLogOut}
              isLoading={false}
              disabled={false}
            >
              Log Out
            </Button>
          </li>
        </ul>
      )}
    </>
  );
});

export default Menu;
