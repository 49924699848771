/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from 'react';
import { Column } from 'react-table';

export interface ITableCheckbox {
  indeterminate?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (event: any) => void;
  disabledOnClick?: boolean;
  title?: string;
}

interface ISelect {
  value: number,
  label: string,
}

export const selectData: Array<ISelect> = [
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
];

export const defaultTableState = {
  filters: [],
  pageIndex: 0,
  pageSize: 10,
  sortBy: [],
  selectedRowIds: {},
  expandedRowIds: {},
};

export interface IContentProps {
  columns: Column[] | ((tooltipRef: MutableRefObject<any>, tableRef?: MutableRefObject<any>) => Column[]);
  data: any;
  className?: string;
  selectable?: boolean;
  isDisabledAllFields?: boolean;
  isAlwaysAllowCheckbox?: boolean | undefined;
  onSelectedRowIdsChange?: (array: any, state) => void;
  onTableStateChange?: (data: any) => void;
  onTableSortByChange?: (sortBy: any) => void;
  paginationable?: boolean;
  showSelectedItems?: boolean;
  showSelectedItemsWithTotal?: boolean;
  manualPagination?: boolean;
  updateTableRowData?: (rowIndex, columnId, value, state?) => void,
  updateColumnData?: (columnId, value, filters, pageIndex, sortBy?) => void,
  updateTableData?: (tableData, expandedRows?, state?) => void;
  setDataRowsRef?: MutableRefObject<any>,
  filter?: any[],
  globalFilterValue?: any,
  setGlobalFilterHandle?: (setGlobalFilter) => void,
  globalFilterFunction?: (rows, ids, query) => any,
  controlledPageIndex?: number,
  selectedIds?: any,
  expanded?: any,
  sort?: any[],
  pageSize?: number,
  renderRowSubComponent?: (array: any) => any;
  noDataTextArr?: string[],
  scrollPositionInitial?: { left: number, top: number },
  onScrollPositionChange?: ({ left, top }: { left: number; top: number }) => void;
  isRowLoading?: { [id: string]: boolean },
  isUseRowSelected?: boolean,
  selectOnlyByRowIds?: boolean,
  subRowAccessor?: string,
  getFlatRows?,
  manualTableStateChange?: boolean,

  selectSubRows?: boolean,
  expandSubRows?: boolean
  paginateExpandedRows?: boolean,
  autoResetSelectedRows?: boolean,
  autoResetExpanded?: boolean,
  autoResetPage?: boolean,
  isAllRowsSelectedByDefault?: boolean,
}

export enum ETableActions {
  FORCE_UPDATE_TABLE_ACTION = 'FORCE_UPDATE_TABLE_ACTION',
}

export const customReducer = (newState, action, previousState, callback) => {
  callback && callback({ newState, action, previousState });

  if (action.type === ETableActions.FORCE_UPDATE_TABLE_ACTION) {
    return { ...newState };
  }
  if (action.type === 'setFilter') {
    return {
      ...newState,
      pageIndex: 0,
    };
  }
  return newState;
};

export const handleAsyncClickRow = async (data, row, callback) => {
  const { id, original } = row;
  const { data: innerData, error } = await callback(original);

  if (error || !innerData.length) {
    return { error, data: [] };
  }
  return { data: recursivelyUpdateTable({ originData: data, childData: innerData, path: id }), error: null };
};

const insertIntoTable = ({ originData, childData, path }) => {
  const id = path[0];

  const isBaseCase = path.length === 1;

  if (isBaseCase) {
    return originData.map((row, index) => {
      const isMatchedRowWithoutSubRows = index === Number(id) && !Boolean(row.subRows?.length);

      if (isMatchedRowWithoutSubRows) {
        return {
          ...row,
          subRows: childData,
        };
      }

      return row;
    });
  }

  return originData.map((row, index) => {
    const isMatchedRowWithSubRows = index === Number(id) && Boolean(row.subRows?.length);

    if (isMatchedRowWithSubRows) {
      const [, ...updatedPath] = path;

      return {
        ...row,
        subRows: insertIntoTable({
          originData: row.subRows,
          childData,
          path: updatedPath,
        }),
      };
    }

    return row;
  });
};

const recursivelyUpdateTable = ({ originData, childData, path }) => {
  return insertIntoTable({
    originData,
    childData,
    path: path.split('.'),
  });
};

export const isRowParent = (id: string) => {
  return id.indexOf('.') === -1;
};

export const getMainRowId = (id: string) => {
  return id.split('.')[0];
};

export const getMainRowById = (rows: any[], searchId: string): any | undefined => {
  return rows.find((d) => d.id === searchId);
};

export const getFlatRows = (rows) => {
  const setSubRows = (prev, current) => {
    const { id, original: { ...rest } } = current;

    prev.push({ id, original: rest });
    current?.subRows?.forEach((i) => {
      const { id, original: { ...rest } } = i;
      prev.push({ id, original: rest });
      setSubRows(prev, i);
    });
  };

  return rows.reduce((prev, current) => {
    setSubRows(prev, current);
    return prev;
  }, []);
};

export const getSelectedRowsBasicTable = <T>
  (selectedRowIds: { [k: string]: boolean }, getRowById: (idsKey: string) => { original: T }) => {
  return Object.keys({ ...selectedRowIds }).reduce((prev: T[], current: string) => {
    if (selectedRowIds[current]) {
      const d = getRowById(current)?.original;
      prev.push({ ...d });
    }
    return prev;
  }, []);
};
