import classNames from 'classnames';
import React from 'react';
import { FC } from 'react';

import './style.scss';
import { ETabMode, TTabsProps } from './types';

const Tab: FC<TTabsProps> = ({ tabs = [], selectedTab = 0, onChange, tabMode = ETabMode.MODE_DISPLAY_NONE }) => {
  const getPanelsByTabMode = () => {
    if (tabMode === ETabMode.MODE_DISPLAY_NONE) {
      return tabs.filter((tab) => tab.disabled !== true).map((tab) => {
        const Panel = tab;
        const style = (selectedTab !== tab.index) ? { display: 'none' } : {};
        return (
          <div key={tab.index} style={style}>
            <Panel.Component key={tab.index} tabIndex={tab.index} selectedTab={selectedTab} />
          </div>
        );
      });
    }
    if (tabMode === ETabMode.MODE_SELECTED_TAB) {
      return tabs.filter((tab) => tab.index === selectedTab).map((tab) => {
        const Panel = tab;
        return (
          <div key={tab.index}>
            <Panel.Component key={tab.index} tabIndex={tab.index} selectedTab={selectedTab} />
          </div>
        );
      });
    }
  };

  return (
    <div className='tab'>
      <div className={'tab__btn'}>
        {tabs.map((tab, index) => (
          <div
            key={tab.index}
            className={classNames(selectedTab === tab.index && 'active', tab?.errors && 'error')}
          >
            <button
              type='button'
              data-qa={`roles-tab-${index + 1}`}
              onClick={() => onChange(tab.index)}
              disabled={tab.disabled}
            >
              <span>{tab.label}</span>
            </button>
          </div>
        ))}
      </div>
      <div className='tab__panel'>
        {getPanelsByTabMode()}
      </div>
    </div>
  );
};
export default Tab;
