import { resetSessionChatApi } from './api/adminApi/sessionChatApi';
import { resetQuestionAnswerApi } from './api/adminApi/questionAnswerApi';
import { resetDocumentsApi } from './api/adminApi/documentsApi';
import { resetSettingsApi } from './api/adminApi/settingsApi';
import { resetTemplateQuestionApi } from './api/adminApi/templateQuestionApi';
import {
  setInitialFetching as setInitialFetchingChat, resetChatStore,
  setMessagesHistory, setIsChatAllowed,
} from './reducers/chatAdminSlice';
import { setUser, logOut, setInitialFetching, setIsLogin, setSessionIdUser } from './reducers/authSlice';

export const rootActions = {
  setUser, logOut, setInitialFetching, setIsLogin, setSessionIdUser,
  resetSessionChatApi,
  resetQuestionAnswerApi,
  resetDocumentsApi,
  resetSettingsApi,
  resetTemplateQuestionApi,

  setInitialFetchingChat, resetChatStore, setMessagesHistory, setIsChatAllowed,
};
