import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import useDetectClickOutside from '../../../hooks/useClickOutside';

import { TooltipCoordinates } from '../types';
import { TOOLTIP_MAX_HEIGHT, TOOLTIP_MAX_WIDTH } from '../constants';

import '../style.scss';
import { getCSSByCoordinates } from '../../../utils/tooltipUtils';

type TooltipProps = {
  children: ReactNode;
  coordinates: TooltipCoordinates;
  onClose: () => void;
  className?: string;
};

const Tooltip = ({
  children,
  coordinates,
  onClose,
  className='',
}: TooltipProps) => {
  const ref = useDetectClickOutside(onClose);
  const { position, top, left, bottom, right, width, overlayWidth } = coordinates;
  const [style, setStyle] = useState<CSSProperties>({
    position: 'absolute',
    maxHeight: TOOLTIP_MAX_HEIGHT,
    maxWidth: Math.min(width, TOOLTIP_MAX_WIDTH),
  });
  const classList = classNames(
    'tooltip',
    {
      'tooltip-top': position === 'top',
      'tooltip-bottom': position === 'bottom',
      'tooltip-right': position === 'right',
      'tooltip-left': position === 'left',
    },
    className,
  );

  useEffect(() => {
    setStyle((prev) => ({
      ...prev,
      ...getCSSByCoordinates(
        ref.current?.offsetHeight || TOOLTIP_MAX_HEIGHT,
        ref.current?.offsetWidth || (width ?? TOOLTIP_MAX_WIDTH),
        {top, left, bottom, right, position, width: overlayWidth}
      ),
    }));
  }, [ref?.current]);

  return (
    <div className={classList} style={style} ref={ref}>
      {children}
    </div>
  );
};

export default Tooltip;
