import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from './App';
import { ConfirmationServiceProvider } from './components/ConfirmationDialog/ConfirmationService';

import { PublicClientApplication } from '@azure/msal-browser';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

export const broadcastChannel = new BroadcastChannel('chatAdminChanel');

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: '/',
  },
});

msalInstance.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConfirmationServiceProvider>
          <App pca={msalInstance} />
        </ConfirmationServiceProvider>
      </BrowserRouter>
    </Provider>
  );
});

