import { ICreateTemplateQuestionDto } from '../../submodules/common/interfaces/dto/template-questions/icreate-chat-location.interface';
import { IGetTemplateQuestionsDto } from '../../submodules/common/interfaces/dto/template-questions/iget-template-questions.interface';
import { IUpdateTemplateQuestionDto } from '../../submodules/common/interfaces/dto/template-questions/iupdate-chat-location.interface';
import { ECrud } from '../../types/crudOperationTypes';

export enum ESuccessMessages {
  CREATE = 'Template question has been created successfully',
  DELETE = 'Template question has been successfully removed',
  UPDATE = 'Template question has been updated successfully',
}

export const title = 'Template questions';

export const defaultSelectedItem: IGetTemplateQuestionsDto = {
  id: '',
  updated: '',
  updatedBy: '',
  location: '',
  questions: '',
};

export const optionsTitle: Record<string, string> = {
  [ECrud.CREATE]: 'Template question',
  [ECrud.UPDATE]: 'Template question',
  [ECrud.INFO]: 'Template question',
};

export const prepareTemplateQuestion = (array: IUpdateTemplateQuestionDto[] | ICreateTemplateQuestionDto[]) => {
  return array.map((i) => {
    return {
      ...i,
      answer: i.answer.trim().split('\n').join('  \n'),
    };
  });
};
