export type TTabComponent = {
  tabIndex: number;
  selectedTab: number;
};

export type TTabType = {
  label: string | JSX.Element;
  index: number;
  Component: React.FunctionComponent<TTabComponent>;
  disabled?: boolean;
  errors?: boolean;
  path?: string | undefined;
  objectName?: string;
};


export type TTabsProps = {
  tabs: TTabType[];
  selectedTab: number;
  onChange: (tab: number) => void;
  tabMode?: ETabMode,
};

export enum ETabMode {
  MODE_DISPLAY_NONE = 'MODE_DISPLAY_NONE',
  MODE_SELECTED_TAB = 'MODE_SELECTED_TAB',
}
